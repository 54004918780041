import React from "react"

import { auth, Firebase } from "../firebase"

type Maybe<T> = T | undefined

export interface FirebaseLoginActions {
  loading: boolean
  error: Maybe<string>
  signin: (email: string, password: string) => Promise<void>
  signup: (email: string, password: string) => Promise<void>
  checkEmail: (email: string) => Promise<boolean>
  sendPasswordResetMail: (email: string, redirect: string) => Promise<void>
  user: Maybe<Firebase.User>
}

export function useFirebaseLogin(): FirebaseLoginActions {
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<Maybe<string>>(undefined)
  const [user, setUser] = React.useState<Maybe<Firebase.User>>(undefined)

  React.useEffect(
    () =>
      auth.onAuthStateChanged(user => {
        setUser(user ?? undefined)
      }),
    []
  )

  const checkError = React.useCallback(function checkError(e: any) {
    if (e.code) {
      if (e.code === "auth/weak-password") {
        setError("The password must be at least 6 characters long.")
      } else if (e.code === "auth/invalid-email") {
        setError("The email address you entered is incorrect.")
      } else if (e.code === "auth/user-not-found") {
        setError("There is no user with this email.")
      } else if (e.code === "auth/wrong-password") {
        setError("The password you entered is incorrect.")
      } else if (e.code === "auth/email-already-in-use") {
        setError("This account already exists.")
      } else if (e.code === "auth/too-many-requests") {
        setError("Too many requests, try again later.")
      } else {
        setError("An unknown error occurred")
        throw e
      }
    }
  }, [])

  const checkEmail = React.useCallback(
    async function checkEmail(email: string) {
      setLoading(true)
      setError(undefined)
      try {
        const methods = await auth.fetchSignInMethodsForEmail(email)
        return methods.length > 0 && methods.some(m => m == "password")
      } catch (e) {
        checkError(e)
        return false
      } finally {
        setLoading(false)
      }
    },
    [checkError]
  )

  const signin = React.useCallback(
    async function signin(email: string, password: string) {
      setLoading(true)
      setError(undefined)
      try {
        await auth.signInWithEmailAndPassword(email, password)
      } catch (e) {
        checkError(e)
      } finally {
        setLoading(false)
      }
    },
    [checkError]
  )

  const signup = React.useCallback(
    async function singup(email: string, password: string) {
      setLoading(true)
      setError(undefined)
      try {
        await auth.createUserWithEmailAndPassword(email, password)
      } catch (e) {
        checkError(e)
      } finally {
        setLoading(false)
      }
    },
    [checkError]
  )

  const sendPasswordResetMail = React.useCallback(
    async function sendPasswordResetMail(email: string, redirect: string) {
      try {
        await auth.sendPasswordResetEmail(email, {
          url: redirect,
        })
      } catch (e) {
        checkError(e)
      }
    },
    [checkError]
  )

  return {
    loading,
    error,
    signin,
    signup,
    checkEmail,
    sendPasswordResetMail,
    user,
  }
}
